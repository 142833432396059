export const DESCRIPTION_LENGTH = 150;
export const CATEGORY = "category";
export const SEARCH = "search";
export const PAGINATION = "pagination";
export const PRICEFILTER = "priceFilter";
export const DEFAULT = "default";
export const PRODUCT_PLACEHOLDER_SRC =
  process.env.REACT_APP_FIREBASE_STORAGE_BASE_URL +
  "/o/global%2Fplaceholder.jpg?alt=media";
export const PRODUCT_PLACEHOLDER_LOCAL_SRC =
  "/assets/img/product/placeholder.webp";
export const RELATED_PRODUCTS_IMG_WIDTH = 250;
export const SMOOVV_SENSE_URL = "smoovv-sense-tandenborstel";
export const SMOOVV_SENSE_URL_PINK = "smoovv-sense-tandenborstel-pink";
export const SMOOVV_SENSE_URL_GOLD = "smoovv-sense-tandenborstel-gold";
export const SMOOVV_SENSE_URL_BLUE = "smoovv-sense-tandenborstel-blue";
export const SMOOVV_SENSE_URL_GREEN = "smoovv-sense-tandenborstel-green";
export const SMOOVV_STARTER_PACK = "SMOOVVBox";
export const SMOOVV_REFILL_BUNDLE = "toothbrush_plus_refill";
