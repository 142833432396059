import {
  ADD_SUBSCRIPTION_TO_CART,
  DECREASE_SUBSCRIPTION_FROM_CART,
  DELETE_SUBSCRIPTION_FROM_CART,
  DELETE_ALL_SUBSCRIPTION_FROM_CART,
  ADD_SUBSCRIPTIONS_DELIVERY_COSTS_TO_CART,
  REMOVE_SUBSCRIPTIONS_DELIVERY_COSTS_FROM_CART,
} from "../actions/subscriptionActions";
import uuid from "uuid/v4";
import { filterSubscription } from "../../helpers/subscription";
import { getDiscountPriceWithDefault } from "../../helpers/product";
import {
  SUBSCRIPTION_REFILL_BASIC,
  SUBSCRIPTION_REFILL_ESSENTIALS,
} from "../../constants/SubscriptionConstants";
import {
  DELIVERY_COST_REFILL_BASIC,
  DELIVERY_COST_REFILL_COMPLETE,
} from "../../constants/DeliveryConstants";

const initState = [];

const subscriptionReducer = (state = initState, action) => {
  const cartSubscriptions = state;
  const subscription = action.payload;

  if (action.type === ADD_SUBSCRIPTION_TO_CART) {
    const cartSubscription = filterSubscription(
      cartSubscriptions,
      subscription
    );

    if (cartSubscription === undefined) {
      return [
        ...cartSubscriptions,
        {
          ...subscription,
          quantity: 1,
          cartItemId: uuid(),
          totalPrice: getDiscountPriceWithDefault(
            subscription.price,
            subscription.discount
          ),
        },
      ];
    } else {
      return cartSubscriptions.map((item) =>
        item.cartItemId === cartSubscription.cartItemId
          ? {
              ...item,
              quantity: item.quantity + 1,
              totalPrice:
                getDiscountPriceWithDefault(
                  subscription.price,
                  subscription.discount
                ) *
                (item.quantity + 1),
            }
          : item
      );
    }
  }

  if (action.type === ADD_SUBSCRIPTIONS_DELIVERY_COSTS_TO_CART) {
    if (cartSubscriptions !== undefined) {
      return cartSubscriptions.map((item) => {
        if (item.name === SUBSCRIPTION_REFILL_BASIC) {
          return {
            ...item,
            deliveryCostTotal: DELIVERY_COST_REFILL_BASIC * item.quantity,
            deliveryCost: DELIVERY_COST_REFILL_BASIC,
          };
        } else {
          return {
            ...item,
            deliveryCostTotal: DELIVERY_COST_REFILL_COMPLETE * item.quantity,
            deliveryCost: DELIVERY_COST_REFILL_COMPLETE,
          };
        }
      });
    }
  }
  if (action.type === REMOVE_SUBSCRIPTIONS_DELIVERY_COSTS_FROM_CART) {
    if (cartSubscriptions !== undefined) {
      return cartSubscriptions.map((item) => {
        if (item.name === SUBSCRIPTION_REFILL_BASIC) {
          return {
            ...item,
            deliveryCostTotal: 0,
            deliveryCost: 0,
          };
        } else {
          return {
            ...item,
            deliveryCostTotal: 0,
            deliveryCost: 0,
          };
        }
      });
    }
  }

  if (action.type === DELETE_SUBSCRIPTION_FROM_CART) {
    if (cartSubscriptions !== undefined) {
      const remainingItems = (cartSubscriptions, subscription) =>
        cartSubscriptions.filter(
          (cartItem) => cartItem.cartItemId !== subscription.cartItemId
        );
      return remainingItems(cartSubscriptions, subscription);
    }
  }

  if (action.type === DELETE_ALL_SUBSCRIPTION_FROM_CART) {
    if (cartSubscriptions !== undefined) {
      return cartSubscriptions.filter((item) => {
        return false;
      });
    }
  }

  if (action.type === DECREASE_SUBSCRIPTION_FROM_CART) {
    if (subscription.quantity === 1) {
      const remainingItems = (cartSubscriptions, subscription) =>
        cartSubscriptions.filter(
          (cartItem) => cartItem.cartItemId !== subscription.cartItemId
        );
      return remainingItems(cartSubscriptions, subscription);
    } else {
      if (cartSubscriptions !== undefined) {
        return cartSubscriptions.map((item) =>
          item.cartItemId === subscription.cartItemId
            ? {
                ...item,
                quantity: item.quantity - 1,
                totalPrice:
                  item.totalPrice -
                  getDiscountPriceWithDefault(
                    item.price,
                    subscription.discount
                  ),
              }
            : item
        );
      }
    }
  }

  return state;
};

export default subscriptionReducer;
