import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EN from "./en.json";
import NL from "./nl.json";
import LanguageDetector from "i18next-browser-languagedetector";

const fallbackLng = ["nl", "en"];
const availableLanguages = ["en", "nl"];

const t = i18n.t.bind(i18n);
export { t };

const resources = {
  en: {
    translation: EN,
  },
  nl: {
    translation: NL,
  },
};

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng,
    keySeparator: ".",

    debug: false,

    whitelist: availableLanguages,
    detection: {
      order: ["localStorage"],
      lookupFromPathIndex: 0,
      lookUpLocalStorage: "i18nextLng",
      checkWhitelist: true,
    },

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  });

export const changeLanguage = (lang) => {
  i18n.changeLanguage(lang);
};

export const currentLanguage = i18n.language;

export default i18n;
