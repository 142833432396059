import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import filterReducer from "./filterReducer";
import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from 'redux-firestore'
import subscriptionReducer from "./subscriptionReducer";

const rootReducer = combineReducers({
  firebaseReducer,
  firestoreReducer,
  cartData: cartReducer,
  cartSubscriptionData: subscriptionReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer,
  filterData: filterReducer,
});

export default rootReducer;
