import uuid from "uuid/v4";
import {
  filterProduct,
  findProductType,
  findProductVariation,
} from "../../helpers/product";
import {
  ADD_MULTIPLE_TO_CART,
  ADD_TO_CART,
  DECREASE_QUANTITY,
  DELETE_ALL_FROM_CART,
  DELETE_FROM_CART,
  UPDATE_CART,
} from "../actions/cartActions";

const initState = [];

const cartReducer = (state = initState, action) => {
  const cartItems = state,
    product = action.payload;

  if (action.type === ADD_TO_CART) {
    const cartItem = filterProduct(cartItems, product);

    if (cartItem === undefined) {
      return [
        ...cartItems,
        {
          ...product,
          quantity: product.quantity ? product.quantity : 1,
          cartItemId: uuid(),
        },
      ];
    } else {
      return cartItems.map((item) =>
        item.cartItemId === cartItem.cartItemId
          ? {
              ...item,
              quantity: product.quantity
                ? item.quantity + product.quantity
                : item.quantity + 1,
            }
          : item
      );
    }
  }

  if (action.type === ADD_MULTIPLE_TO_CART) {
    let cartItemsList = [...cartItems];
    const products = action.payload;
    products.forEach((product) => {
      const cartItem = filterProduct(cartItems, product);
      if (cartItem === undefined) {
        cartItemsList.push({
          ...product,
          quantity: product.quantity ? product.quantity : 1,
          cartItemId: uuid(),
        });
      } else {
        let existingCartItemProduct = cartItemsList.find(
          (item) => item.cartItemId === cartItem.cartItemId
        );
        let index = cartItemsList.findIndex(
          (item) => item.cartItemId === cartItem.cartItemId
        );
        cartItemsList[index].quantity = product.quantity
          ? existingCartItemProduct.quantity + product.quantity
          : existingCartItemProduct.quantity + 1;
      }
    });
    return cartItemsList;
  }

  if (action.type === DECREASE_QUANTITY) {
    if (product.quantity === 1) {
      const remainingItems = (cartItems, product) =>
        cartItems.filter(
          (cartItem) => cartItem.cartItemId !== product.cartItemId
        );
      return remainingItems(cartItems, product);
    } else {
      return cartItems.map((item) =>
        item.cartItemId === product.cartItemId
          ? { ...item, quantity: item.quantity - 1 }
          : item
      );
    }
  }

  if (action.type === DELETE_FROM_CART) {
    const remainingItems = (cartItems, product) =>
      cartItems.filter(
        (cartItem) => cartItem.cartItemId !== product.cartItemId
      );
    return remainingItems(cartItems, product);
  }

  if (action.type === DELETE_ALL_FROM_CART) {
    return cartItems.filter((item) => {
      return false;
    });
  }

  if (action.type === UPDATE_CART) {
    const products = action.payload;
    return cartItems.reduce(function (updatedCart, cartItem) {
      const productFound = products.find(
        (product) => product.id === cartItem.id
      );
      if (!!productFound && productFound.stock > 0) {
        const updatedCartItem = {
          ...cartItem,
          name: productFound.name,
          image: productFound.image,
          price: productFound.price,
          discount: productFound.discount,
          taxRate: productFound.taxRate,
          stock: productFound.stock,
          variation: findProductVariation(productFound, cartItem),
          type: findProductType(productFound, cartItem),
        };
        updatedCart.push(updatedCartItem);
      }
      return updatedCart;
    }, []);
  }

  return state;
};

export default cartReducer;
