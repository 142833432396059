import {
  SUBSCRIPTION_INTERVAL_ONE_MONTH,
  SUBSCRIPTION_INTERVAL_THREE_MONTHS,
  SUBSCRIPTION_INTERVAL_TWO_MONTHS,
  SUBSCRIPTION_REFILL_BASIC,
} from "../constants/SubscriptionConstants";
import moment from "moment";
import {
  DELIVERY_COST_REFILL_BASIC,
  DELIVERY_COST_REFILL_COMPLETE,
} from "../constants/DeliveryConstants";

export const calculateSubscriptionStartDate = (interval) => {
  if (interval === SUBSCRIPTION_INTERVAL_ONE_MONTH) {
    let subscriptionStartDate = moment().add(1, "M");
    return subscriptionStartDate.format("YYYY-MM-DD");
  } else if (interval === SUBSCRIPTION_INTERVAL_TWO_MONTHS) {
    let subscriptionStartDate = moment().add(2, "M");
    return subscriptionStartDate.format("YYYY-MM-DD");
  } else if (interval === SUBSCRIPTION_INTERVAL_THREE_MONTHS) {
    let subscriptionStartDate = moment().add(3, "M");
    return subscriptionStartDate.format("YYYY-MM-DD");
  }
};

export const calculateRefillDeliveryCosts = (subscriptionName) => {
  if (subscriptionName === SUBSCRIPTION_REFILL_BASIC)
    return DELIVERY_COST_REFILL_BASIC;
  else return DELIVERY_COST_REFILL_COMPLETE;
};

export const calculateSubscriptionDeliveryCosts = (cartSubscriptions) => {
  let subscriptionCost = 0;
  cartSubscriptions.map((cartSubscription) => {
    let deliveryCost = calculateRefillDeliveryCosts(cartSubscription.name);
    subscriptionCost += cartSubscription.quantity * deliveryCost;
  });
  return subscriptionCost;
};
