let paymentAPI = process.env.REACT_APP_API_URL + "/api/payment/mollie";
let mailService = process.env.REACT_APP_API_URL + "/api/email";
let authService = process.env.REACT_APP_API_URL + "/api/auth";
let klaviyoService = process.env.REACT_APP_API_URL + "/api/klaviyo";
module.exports = {
  home: "/",
  about: "/ons-verhaal",
  contact: "/contact",
  my_account: "/my-account",
  my_orders: "/my-orders",
  my_subscriptions: "/my-subscriptions",
  login_register: "/login-register",
  forgot_password: "/reset-password",
  cart: "/cart",
  checkout: "/checkout",
  document_id_param: ":documentId",
  order_processing: "/bestelling/verwerken/",
  order_confirmed: "/bestelling/bevestigd",
  not_found: "/niet-gevonden",
  blog: "/blog/",
  blogDetail: "/blog/:url",
  shopLandingPage: "/losse-producten",
  shop: "/shop",
  other_products: "/losse-producten",
  productsByCategory: "/producten/:category",
  product: "/product/",
  productDetail: "/product/:url",
  categorySlug: "/categorie/",
  categoryLandingPage: "/categorie/:category",
  privacy: "/privacy-statement",
  terms_conditions: "/algemene-voorwaarden",
  cookie_statement: "/cookieverklaring-smoovv",
  mollie_single_payment: paymentAPI + "/checkout",
  mollie_cancel_subscription: paymentAPI + "/subscription/cancel",
  mollie_update_subscription: paymentAPI + "/subscription/update",
  order_confirmed_email: mailService + "/order/confirmed",
  contact_email: mailService + "/contact",
  product_return_email: mailService + "/product/return",
  order_invoice: mailService + "/order/invoice",
  welcome_email: mailService + "/signup/welcome",
  verify_email: mailService + "/signup/verify",
  email_verified: "/email/verificatie/:encodedEmail",
  email_verified_link: "/email/verificatie/",
  verify_user_email: authService + "/verify/",
  returns: "/retourneren",
  faq: "/faq",
  smoovv_box: "/smoovvbox",
  refill_plan: "/refill-plans",
  smoovv_sense: "/product/smoovv-sense-tandenborstel/",
  smoovv_sense_black: "/product/smoovv-sense-tandenborstel-black/",
  smoovv_sense_pink: "/product/smoovv-sense-tandenborstel-pink/",
  smoovv_sense_gold: "/product/smoovv-sense-tandenborstel-gold/",
  smoovv_sense_blue: "/product/smoovv-sense-tandenborstel-blue/",
  smoovv_sense_green: "/product/smoovv-sense-tandenborstel-green/",
  smoovv_travel_case: "/product/travelcase/",
  smoovv_sense_charging_station: "/product/oplaadstation/",
  smoovv_sense_tongue_scraper: "/product/tongschraper/",
  smoovv_sense_brush_head: "/product/opzetborstel/",
  blog_1: "/blog/help-ik-wil-mijn-tandenbleken/",
  blog_2: "/blog/waarom-een-refill-plan/",
  blog_3: "/blog/diabetes-en-mijn-tandvlees/",
  blog_4: "/blog/tandpasta-met-of-zonder-fluoride/",
  blog_5: "/blog/wat-zijn-de-voordelen-van-een-tongscraper/",
  blog_6: "/blog/werkt-whitening-tandpasta/",
  blog_7: "/blog/smoovv-routine/",
  burst: "/burst/",
  tongscraper: "/tongschraper/",
  mondwater: "/mondwater/",
  philips_sonicare: "/philips-sonicare/",
  oral_b_io: "/oral-b-io/",
  tandenborstel: "/tandenborstel/",
  oral_b_tandenborstel: "/oral-b-tandenborstel/",
  elektrische_tandenborstel: "/elektrische-tandenborstel/",
  mondzorgtips: "/mondzorgtips/",
  blackFridayPromotion: "/black-friday",
  addEmailToKlaviyo: klaviyoService + "/lists/add_member",
};
