import _ from "lodash";
import { calculateSubscriptionStartDate } from "./calculations";
import { formatPriceForMollie } from "./formatters";
import { getDiscountPrice, getDiscountPriceWithDefault } from "./product";
import uuid from "uuid/v4";
import { customAlphabet } from "nanoid";
import {
  SUBSCRIPTION_INTERVAL_ONE_MONTH,
  SUBSCRIPTION_INTERVAL_THREE_MONTHS,
  SUBSCRIPTION_INTERVAL_TWO_MONTHS,
} from "../constants/SubscriptionConstants";

const nanoid = customAlphabet("1234567890", 5);
/**
 * Filter cart subscription depending on the color of subscription. Used in subscription reducer.
 * @param cartSubscription
 * @param subscription
 * @returns Array of subscriptions
 */
export const filterSubscription = (cartSubscription, subscription) => {
  return cartSubscription.filter(
    (item) =>
      item.id === subscription.id &&
      item.colorVariant === subscription.colorVariant &&
      item.interval === subscription.interval
  )[0];
};

/**
 * Merge multiple cart subscriptions so they are grouped by interval and have a total price per interval.
 * Here we use the sum of prices, because each subscription has been split by quantity
 * @param cartSubscriptions Cart subscriptions from Redux store
 * @returns Array of subscriptions grouped by interval
 */
export const mergeCartSubscriptionAfterCheckout = (cartSubscriptions) => {
  if (!!cartSubscriptions) {
    let subscriptions = cartSubscriptions.map((subscription) => ({
      ...subscription,
      discountPrice: getDiscountPriceWithDefault(
        subscription.price,
        subscription.discount
      ),
    }));
    return _(subscriptions)
      .groupBy("interval")
      .map((objs, key) => ({
        interval: key,
        totalPrice: _.sumBy(objs, "discountPrice"),
        deliveryCosts: _.sumBy(objs, "deliveryCost"),
      }))
      .value();
  }
};

/**
 * Merge multiple cart subscriptions so they are grouped by interval and have a total price per interval.
 * Here we use the sum of total prices, because eall the subscriptions have been grouped
 * @param cartSubscriptions Cart subscriptions from Redux store
 * @returns Array of subscriptions grouped by interval
 */
export const mergeCartSubscription = (cartSubscriptions) => {
  if (!!cartSubscriptions) {
    return _(cartSubscriptions)
      .groupBy("interval")
      .map((objs, key) => ({
        interval: key,
        totalPrice: _.sumBy(objs, "totalPrice"),
        deliveryCostTotal: _.sumBy(objs, "deliveryCostTotal"),
      }))
      .value();
  }
};

/**
 * Map Cart Redux subscriptions to Firestore compatible subscriptions
 * @param cartSubscriptions Cart subscriptions from Redux store
 * @returns Array of subscriptions
 */
export const mapSubscriptionsForFirestore = (cartSubscriptions) => {
  let mollieSubscriptions = [];
  cartSubscriptions.forEach((subscription) => {
    let mollieSubscription = mapSubscriptionForMollie(subscription);
    mollieSubscriptions.push(mollieSubscription);
  });
  return mollieSubscriptions;
};

/**
 * Map Cart Redux subscription to Mollie compatible subscription
 * @param subscription Cart subscription from Redux store
 * @returns Object containing a subscription
 */
export const mapSubscriptionForMollie = (subscription) => {
  let subscriptionStartDate = calculateSubscriptionStartDate(
    subscription.interval
  );
  let subscriptionPrice = subscription.price + subscription.deliveryCost;
  return {
    price: subscription.price,
    molliePrice: formatPriceForMollie(subscriptionPrice),
    mollieDiscountPrice: formatPriceForMollie(
      subscription.discount
        ? getDiscountPrice(subscription.price, subscription.discount) +
            subscription.deliveryCost
        : subscriptionPrice
    ),
    discount: subscription.discount,
    interval: subscription.interval,
    startDate: subscriptionStartDate,
    description:
      subscription.name +
      " " +
      subscription.colorVariant +
      " (" +
      nanoid() +
      ")",
    subscriptionProductId: subscription.id,
    name: subscription.name,
    identifierId: uuid(),
    colorVariant: subscription.colorVariant,
    quantity: !!subscription.quantity ? subscription.quantity : 1,
    id: subscription.id,
    image: subscription.image,
    productItems: subscription.productItems,
    taxRate: subscription.taxRate,
    totalPrice: subscription.totalPrice,
    deliveryCost: subscription.deliveryCost,
  };
};

/**
 * Map Customer subscription for Firestore when modifying subscription
 * @param subscription Customer subscription from Redux store
 * @returns Object containing a customer subscription
 */
export const mapCustomerSubscriptionForFirestore = (subscription) => {
  return {
    colorVariant: subscription.colorVariant,
    discount: subscription.discount,
    email: subscription.email,
    identifierId: subscription.identifierId,
    interval: subscription.interval,
    mollieDiscountPrice: formatPriceForMollie(
      subscription.discount
        ? getDiscountPrice(subscription.price, subscription.discount)
        : subscription.price
    ),
    molliePrice: formatPriceForMollie(subscription.price),
    name: subscription.name,
    orderId: subscription.orderId,
    price: subscription.price,
    startDate: subscription.startDate,
    // updatedStartDate: subscriptionStartDate,
    status: subscription.status,
    subscriptionId: subscription.subscriptionId,
    subscriptionProductId: subscription.subscriptionProductId,
    taxRate: subscription.taxRate,
    description:
      subscription.name +
      " " +
      subscription.colorVariant +
      " (" +
      nanoid() +
      ")",
    intervalModifications: subscription.intervalModifications,
  };
};

/**
 * Get subscription interval
 * @param interval
 */
export const calculateSubscriptionInterval = (interval) => {
  if (interval === SUBSCRIPTION_INTERVAL_ONE_MONTH) {
    return 1;
  } else if (interval === SUBSCRIPTION_INTERVAL_TWO_MONTHS) {
    return 2;
  } else if (interval === SUBSCRIPTION_INTERVAL_THREE_MONTHS) {
    return 3;
  }
};
