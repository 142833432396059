// Tables
export const FIRESTORE_ORDERS_TABLE = "orders";
export const FIRESTORE_USERS_TABLE = "users";
export const FIRESTORE_DISCOUNT_CODES_TABLE = "discountCodes";
export const FIRESTORE_PRODUCTS_TABLE = "products";
export const FIRESTORE_CUSTOMER_SUBSCRIPTIONS_TABLE = "customerSubscriptions";
export const FIRESTORE_SUBSCRIPTIONS_TABLE = "subscriptions";
export const FIRESTORE_FEATURED_PRODUCTS_TABLE = "featuredProducts";
export const FIRESTORE_EMAIL_LISTS_TABLE = "emailLists";

// Documents
export const FIRESTORE_DISCOUNT_POPUP_DOCUMENT = "discountCodePopup";
