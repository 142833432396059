import { transparentize } from "./helperFn";

export const colors = {
  primary: "#005F59",
  primary80: transparentize("#005F59", 0.8),
  secondary: "#BB967E",
  secondaryLight: "#DCCABC",
  dark: "#343434",
  light: "#E1D8CF",
  lightBg: "#F2EEEA",
  text: "#3B3F33",
  btn: "#4B5A57",
  cta_btn: "#005F59",
  cta_btn_active: transparentize("#005F59", 0.8),
  cta_btn_text: "#FFFFFF",
  standout_text: "#005F59",
  added_to_cart_btn: "#009d00",
  category_header: "#e5e5e5",
  clickable_text: "#0066ff",
  hamburger: "#005F59",
  menu_items: "#222",
  header: "#005F59",
  subheader: "#BB967E",
  light_bg: "#F2EEEA",
  text_shadow_header: "0 2px rgb(86 86 86 / 15%)",
};

export const styles = {
  btn_border_radius: "5px",
  card_border_radius: "10px",
  bullet_point_icon: "✔️  ",
};
