import { DESCRIPTION_LENGTH } from "../constants/ProductConstants";
import moment from "moment";
import i18n from "../translations/i18n";
import {
  SUBSCRIPTION_INTERVAL_ONE_MONTH,
  SUBSCRIPTION_INTERVAL_THREE_MONTHS,
  SUBSCRIPTION_INTERVAL_TWO_MONTHS,
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_CANCELED,
  SUBSCRIPTION_STATUS_EXPIRED,
} from "../constants/SubscriptionConstants";
import {
  DUO_PACK_DISCOUNT_ID,
  REFILL_DISCOUNT_ID,
} from "../constants/DiscountConstants";

export const formatPrice = function (value) {
  if (!!value)
    return value.toLocaleString("nl-NL", {
      minimumFractionDigits: value % 1 === 0 ? 0 : 2,
      maximumFractionDigits: 2,
    });
  else return 0;
};

export const formatPriceForMollie = (price) => {
  return price.toFixed(2);
};

export const formatWeight = function (value) {
  value = parseInt(value);
  return value.toLocaleString("nl-NL", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
};

export const formatDescription = function (value) {
  return value.length > DESCRIPTION_LENGTH
    ? value.substring(0, DESCRIPTION_LENGTH - 3) + "..."
    : value.substring(0, DESCRIPTION_LENGTH);
};

export const formatOrderDate = (timestamp) => {
  return moment.unix(timestamp).format("DD/MM/YYYY");
};

export const formatInvoiceFileName = (invoice, orderId) => {
  return invoice + "-" + orderId + ".pdf";
};

export const formatNameToURL = (name) => {
  if (!name) return null;
  name = name.replace(/^\s+|\s+$/g, "");
  name = name.toLowerCase();

  // remove accents, swap ñ for n, etc
  let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  let to = "aaaaeeeeiiiioooouuuunc------";
  for (let i = 0, l = from.length; i < l; i++) {
    name = name.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  // remove invalid chars
  // collapse whitespace and replace by -
  // collapse dashes
  name = name
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");

  return name;
};

export const formatUrlToName = (url) => {
  if (!url) return null;

  // Replace dashes with spaces
  url = url
    .replace(/^\s+|\s+$/g, "")
    .replace(/-/g, " ")
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
  return url;
};

/**
 * Rounds float to 2 decimals
 * @param {*} value The number to be converted
 * @returns Number rounded to two decimals
 */
export const roundPrice = (value) => {
  return Math.round(value * 100) / 100;
};

export const getSubscriptionStatus = (status) => {
  if (status === SUBSCRIPTION_STATUS_ACTIVE)
    return i18n.t("subscription.status_active");
  else if (status === SUBSCRIPTION_STATUS_EXPIRED)
    return i18n.t("subscription.status_expired");
  else if (status === SUBSCRIPTION_STATUS_CANCELED)
    return i18n.t("subscription.status_canceled");
};

export const getSubscriptionStatusColor = (status) => {
  if (status === SUBSCRIPTION_STATUS_ACTIVE) return "geekblue";
  else if (status === SUBSCRIPTION_STATUS_EXPIRED) return "red";
  else if (status === SUBSCRIPTION_STATUS_CANCELED) return "orange";
};

export const getSubscriptionInterval = (interval) => {
  if (interval === SUBSCRIPTION_INTERVAL_ONE_MONTH)
    return i18n.t("subscription.interval_one_month");
  else if (interval === SUBSCRIPTION_INTERVAL_TWO_MONTHS)
    return i18n.t("subscription.interval_two_months");
  else if (interval === SUBSCRIPTION_INTERVAL_THREE_MONTHS)
    return i18n.t("subscription.interval_three_months");
};

/**
 * Turn variation into a title that can be used in JSX
 * */
export const formatVariationToTitle = (variation) => {
  switch (variation.type) {
    case "WEIGHT":
      return variation.weight + " gram";
    default:
      return variation.weight + " gram";
  }
};

/**
 * Format subscription interval to a readable string
 * @param interval
 * @returns {string}
 */
export const formatSubscriptionInterval = (interval) => {
  switch (interval) {
    case SUBSCRIPTION_INTERVAL_ONE_MONTH:
      return i18n.t("every_month");
    case SUBSCRIPTION_INTERVAL_TWO_MONTHS:
      return i18n.t("every_other_month");
    case SUBSCRIPTION_INTERVAL_THREE_MONTHS:
      return i18n.t("every_quarter");
    default:
      return i18n.t("every_month");
  }
};

/**
 * Format grand total for subscription to a readable string
 * @param interval
 * @returns {string}
 */
export const formatSubscriptionGrandTotal = (interval) => {
  switch (interval) {
    case SUBSCRIPTION_INTERVAL_ONE_MONTH:
      return i18n.t("grand_total_monthly");
    case SUBSCRIPTION_INTERVAL_TWO_MONTHS:
      return i18n.t("grand_total_other_monthly");
    case SUBSCRIPTION_INTERVAL_THREE_MONTHS:
      return i18n.t("grand_total_quarterly");
    default:
      return i18n.t("grand_total_monthly");
  }
};

/**
 * Get a text representing the next moment user will get billed
 * @param interval Interval of the subscription
 * @param date Date from which to calculate next billing moment
 * @returns {string}
 */
export const formatSubscriptionEarliestBillingMoment = (interval, date) => {
  moment.locale(i18n.language);
  let futureDate;
  switch (interval) {
    case SUBSCRIPTION_INTERVAL_ONE_MONTH:
      futureDate = moment(date).add(1, "month");
      break;
    case SUBSCRIPTION_INTERVAL_TWO_MONTHS:
      futureDate = moment(date).add(2, "month");
      break;
    case SUBSCRIPTION_INTERVAL_THREE_MONTHS:
      futureDate = moment(date).add(3, "month");
      break;
    default:
      futureDate = moment(date).add(1, "month");
      break;
  }
  return i18n.t("first_payment_on") + " " + moment(futureDate).format("LL");
};

export const formatAdditionalDiscount = (type) => {
  switch (type) {
    case DUO_PACK_DISCOUNT_ID:
      return i18n.t("duo_pack_discount");
    case REFILL_DISCOUNT_ID:
      return i18n.t("refill_discount");
    default:
      return i18n.t("discount");
  }
};
