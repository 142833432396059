export const FREE_DELIVERY_FROM_PRICE = 49.99;
export const SEND_COST = 3.95;
export const SEND_COST_EU = 10.0;
export const CURRENCY = "€ ";
export const CURRENCY_CODE = "EUR";
export const ADDRESS_TYPES = {
  DELIVERY: "DELIVERY",
  INVOICE: "INVOICE",
};
export const DELIVERY_COST_REFILL_BASIC = 0.99;
export const DELIVERY_COST_REFILL_COMPLETE = 2.49;
