import React, { lazy, Suspense, useEffect } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./translations/i18n";
import urls from "./urls";
import { useFirestoreConnect } from "react-redux-firebase";
import { Loader } from "./components/loader/Loader";
import moment from "moment";
import localization from "moment/locale/nl";
import { updateCart } from "./redux/actions/cartActions";
import {
  FIRESTORE_CUSTOMER_SUBSCRIPTIONS_TABLE,
  FIRESTORE_FEATURED_PRODUCTS_TABLE,
  FIRESTORE_ORDERS_TABLE,
  FIRESTORE_PRODUCTS_TABLE,
  FIRESTORE_SUBSCRIPTIONS_TABLE,
} from "./constants/FirebaseConstants";
import {
  SMOOVV_SENSE_URL,
  SMOOVV_SENSE_URL_BLUE,
  SMOOVV_SENSE_URL_GOLD,
  SMOOVV_SENSE_URL_GREEN,
  SMOOVV_SENSE_URL_PINK,
} from "./constants/ProductConstants";

import CookieBanner from "./components/banner/CookieBanner";
import { ORDER_STATUS_CREATED } from "./constants/OrderConstants";
import ReactPixel from "react-facebook-pixel";
import TiktokPixel from "tiktok-pixel";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

moment.locale("nl", localization);

const Home = lazy(() => import("./pages/home/Home"));
// shop pages
const ShopListStandard = lazy(() => import("./pages/shop/ShopList"));
const CategoryLandingPage = lazy(() => import("./pages/shop/CategoryLanding"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

// blog pages
const BlogOverview = lazy(() => import("./pages/blog/BlogOverview"));
const BlogDetail = lazy(() => import("./pages/blog/BlogDetail"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const MyOrders = lazy(() => import("./pages/other/MyOrders"));
const MySubscription = lazy(() => import("./pages/other/MySubscription"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const ForgotPassword = lazy(() => import("./pages/other/ForgotPassword"));
const VerifyEmail = lazy(() => import("./pages/other/VerifyEmail"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const OrderConfirmed = lazy(() => import("./pages/other/OrderConfirmed"));
const OrderProcessing = lazy(() => import("./pages/other/OrderProcessing"));
const PrivacyPolicy = lazy(() => import("./pages/other/PrivacyPolicy"));
const TermsConditions = lazy(() => import("./pages/other/TermsConditions"));
const CookieStatement = lazy(() => import("./pages/other/CookieStatement"));
const FAQ = lazy(() => import("./pages/other/FAQ"));
const Returns = lazy(() => import("./pages/other/Returns"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

// SMOOVV pages
const Refill = lazy(() => import("./pages/smoovv/RefillPlan"));
const SmoovvBox = lazy(() => import("./pages/smoovv/SmoovvBox"));
const SmoovvSense = lazy(() => import("./pages/smoovv/SmoovvSense"));
const BlackFridayPromotion = lazy(() =>
  import("./pages/smoovv/BlackFridayPromotion")
);

// SMOOVV SEO pages
const TongScraper = lazy(() => import("./pages/smoovv/seo/TongScraper"));
const Mondwater = lazy(() => import("./pages/smoovv/seo/Mondwater"));
const PhilipsSonicare = lazy(() =>
  import("./pages/smoovv/seo/PhilipsSonicare")
);
const OralBIO = lazy(() => import("./pages/smoovv/seo/OralBIO"));
const Tandenborstel = lazy(() => import("./pages/smoovv/seo/Tandenborstel"));
const MondzorgTips = lazy(() => import("./pages/smoovv/seo/MondzorgTips"));
const ElektrischeTandenborstel = lazy(() =>
  import("./pages/smoovv/seo/ElektrischeTandenborstel")
);
const OralBTandenborstel = lazy(() =>
  import("./pages/smoovv/seo/OralBTandenborstel")
);

const App = () => {
  const { t } = useTranslation();
  const email = useSelector((state) => state.firebaseReducer.auth.email);
  let queries = [
    { collection: FIRESTORE_PRODUCTS_TABLE },
    { collection: FIRESTORE_SUBSCRIPTIONS_TABLE },
    { collection: FIRESTORE_FEATURED_PRODUCTS_TABLE },
  ];
  // If user is logged in, get his/her orders
  if (!!email)
    queries.push(
      {
        collection: FIRESTORE_ORDERS_TABLE,
        where: [
          ["customer.email", "==", email],
          ["orderStatus", "!=", ORDER_STATUS_CREATED],
        ],
      },
      {
        collection: FIRESTORE_CUSTOMER_SUBSCRIPTIONS_TABLE,
        where: [["email", "==", email]],
      }
    );
  useFirestoreConnect(queries);

  const dispatch = useDispatch();
  const products = useSelector(
    (state) => state.firestoreReducer.ordered.products
  );

  // useEffect(() => {
  //   showNotification();
  // }, []);

  useEffect(() => {
    dispatch(updateCart(products));
  }, [products]);

  const showNotification = () => {
    notification["info"]({
      message: t("notifications.february_promotion_title"),
      description: (
        <div>
          <span style={{ whiteSpace: "pre-line" }}>
            {t("notifications.february_promotion_description")}
          </span>
        </div>
      ),
      duration: 0,
    });
  };

  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    const fbOptions = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    const tikTokOptions = {
      debug: false, // enable logs
    };
    TiktokPixel.init("CD985DRC77UAS3RRISFG", null, tikTokOptions);
    ReactPixel.init("267699678482798", null, fbOptions);
  }

  if (!products) return <Loader />;
  return (
    <>
      <Router>
        <ScrollToTop>
          <Suspense fallback={<Loader />}>
            <Switch>
              {/* Homepage */}
              <Route exact path={urls.home} component={Home} />
              {/* SMOOVV pages */}
              <Route path={urls.refill_plan} component={Refill} />
              <Route path={urls.smoovv_box} component={SmoovvBox} />
              {/* SMOOVV Sense Page */}
              <Route
                path={urls.smoovv_sense}
                render={(routeProps) => (
                  <SmoovvSense {...routeProps} url={SMOOVV_SENSE_URL} />
                )}
              />
              {/* SMOOVV Sense Color Landing Pages (for SEA) */}
              <Route
                path={urls.smoovv_sense_black}
                render={(routeProps) => (
                  <SmoovvSense {...routeProps} url={SMOOVV_SENSE_URL} />
                )}
              />
              <Route
                path={urls.smoovv_sense_pink}
                render={(routeProps) => (
                  <SmoovvSense {...routeProps} url={SMOOVV_SENSE_URL_PINK} />
                )}
              />
              <Route
                path={urls.smoovv_sense_green}
                render={(routeProps) => (
                  <SmoovvSense {...routeProps} url={SMOOVV_SENSE_URL_GREEN} />
                )}
              />
              <Route
                path={urls.smoovv_sense_gold}
                render={(routeProps) => (
                  <SmoovvSense {...routeProps} url={SMOOVV_SENSE_URL_GOLD} />
                )}
              />
              <Route
                path={urls.smoovv_sense_blue}
                render={(routeProps) => (
                  <SmoovvSense {...routeProps} url={SMOOVV_SENSE_URL_BLUE} />
                )}
              />
              <Route
                path={urls.blackFridayPromotion}
                component={BlackFridayPromotion}
              />

              {/* Shop pages */}
              <Route exact path={urls.shop} component={ShopListStandard} />
              <Route
                exact
                path={urls.other_products}
                component={ShopListStandard}
              />
              <Route
                exact
                path={urls.productsByCategory}
                component={ShopListStandard}
              />
              {/* Shop product pages */}
              <Route
                path={urls.productDetail}
                render={(routeProps) => (
                  <Product
                    {...routeProps}
                    key={routeProps.match.params.id}
                    clickedProduct={routeProps.location.product}
                  />
                )}
              />
              {/* SMOOVV SEO pages */}
              <Route path={urls.tongscraper} component={TongScraper} />
              <Route path={urls.mondwater} component={Mondwater} />
              <Route path={urls.philips_sonicare} component={PhilipsSonicare} />
              <Route path={urls.oral_b_io} component={OralBIO} />
              <Route path={urls.tandenborstel} component={Tandenborstel} />
              <Route path={urls.mondzorgtips} component={MondzorgTips} />
              <Route
                path={urls.oral_b_tandenborstel}
                component={OralBTandenborstel}
              />
              <Route
                path={urls.elektrische_tandenborstel}
                component={ElektrischeTandenborstel}
              />

              {/* Category landing page */}
              <Route
                exact
                path={urls.categoryLandingPage}
                component={CategoryLandingPage}
              />

              {/* Blog pages */}
              <Route exact path={urls.blog} component={BlogOverview} />
              <Route exact path={urls.blogDetail} component={BlogDetail} />
              {/* Other pages */}
              <Route path={urls.about} component={About} />
              <Route path={urls.contact} component={Contact} />
              <Route path={urls.my_account} component={MyAccount} />
              <Route path={urls.my_orders} component={MyOrders} />
              <Route path={urls.my_subscriptions} component={MySubscription} />
              <Route path={urls.login_register} component={LoginRegister} />
              <Route path={urls.forgot_password} component={ForgotPassword} />
              <Route path={urls.email_verified} component={VerifyEmail} />
              <Route path={urls.privacy} component={PrivacyPolicy} />
              <Route path={urls.terms_conditions} component={TermsConditions} />
              <Route path={urls.cookie_statement} component={CookieStatement} />
              <Route path={urls.cart} component={Cart} />
              <Route path={urls.checkout} component={Checkout} />
              <Route path={urls.order_confirmed} component={OrderConfirmed} />
              <Route
                path={urls.order_processing + urls.document_id_param}
                component={OrderProcessing}
              />
              <Route path={urls.not_found} component={NotFound} />
              <Route path={urls.faq} component={FAQ} />
              <Route path={urls.returns} component={Returns} />
              <Route exact component={NotFound} />
            </Switch>
          </Suspense>
        </ScrollToTop>
        <CookieBanner />
      </Router>
    </>
  );
};

export default App;
