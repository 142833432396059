export const ADD_SUBSCRIPTION_TO_CART = "ADD_SUBSCRIPTION_TO_CART";
export const DELETE_SUBSCRIPTION_FROM_CART = "DELETE_SUBSCRIPTION_FROM_CART";
export const DECREASE_SUBSCRIPTION_FROM_CART =
  "DECREASE_SUBSCRIPTION_FROM_CART";
export const DELETE_ALL_SUBSCRIPTION_FROM_CART =
  "DELETE_ALL_SUBSCRIPTION_FROM_CART";
export const ADD_SUBSCRIPTIONS_DELIVERY_COSTS_TO_CART =
  "ADD_SUBSCRIPTIONS_DELIVERY_COSTS_TO_CART";
export const REMOVE_SUBSCRIPTIONS_DELIVERY_COSTS_FROM_CART =
  "REMOVE_SUBSCRIPTIONS_DELIVERY_COSTS_FROM_CART";

export const addSubscriptionToCart = (item) => {
  return (dispatch) => {
    dispatch({
      type: ADD_SUBSCRIPTION_TO_CART,
      payload: {
        ...item,
      },
    });
  };
};

export const deleteSubscriptionFromCart = (item) => {
  return (dispatch) => {
    dispatch({ type: DELETE_SUBSCRIPTION_FROM_CART, payload: item });
  };
};

export const deleteAllSubscriptionFromCart = () => {
  return (dispatch) => {
    dispatch({ type: DELETE_ALL_SUBSCRIPTION_FROM_CART });
  };
};

export const decreaseSubscriptionFromCart = (item) => {
  return (dispatch) => {
    dispatch({ type: DECREASE_SUBSCRIPTION_FROM_CART, payload: item });
  };
};

export const addSubscriptionDeliveryCostsToCart = () => {
  return (dispatch) => {
    dispatch({
      type: ADD_SUBSCRIPTIONS_DELIVERY_COSTS_TO_CART,
    });
  };
};

export const removeSubscriptionDeliveryCostsFromCart = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_SUBSCRIPTIONS_DELIVERY_COSTS_FROM_CART,
    });
  };
};
